import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { SignInButton, SignOutButton, SignedIn, SignedOut, useAuth, useUser } from '@clerk/clerk-react';
import { Amount, Transaction, TransactionFrequency, UnitType } from './gen/transaction/v1/transaction_pb';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { Duration, ServiceType, Timestamp } from '@bufbuild/protobuf';
import { db } from './models/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { LedgerService } from './gen/ledger/v1/ledger_connect';
import { get, set } from 'idb-keyval';

import { AiFillAlert, AiFillBank, AiFillCheckCircle, AiFillCloud, AiFillCustomerService, AiFillDelete, AiFillDownCircle, AiFillDownSquare, AiFillEye, AiFillEyeInvisible, AiFillFormatPainter, AiFillHeart, AiFillMobile, AiFillMoneyCollect, AiFillPlusSquare, AiFillQuestionCircle, AiFillRocket, AiFillSafetyCertificate, AiFillSignal, AiFillSignature, AiFillTwitterCircle, AiFillUpCircle, AiFillUpSquare, AiFillWarning, AiOutlineAlert, AiOutlineCloud, AiOutlineCloudServer, AiOutlineCloudSync, AiOutlineExclamationCircle, AiOutlineFileSync, AiOutlineFileText, AiOutlineMenu, AiOutlinePaperClip, AiOutlineQuestion, AiOutlineQuestionCircle, AiOutlineSplitCells, AiOutlineSync, AiOutlineWallet, AiOutlineWarning, AiTwotoneQuestionCircle } from 'react-icons/ai'
import { Account, Income, LocalData, WidgetConfig } from './gen/account/v1/account_pb';
import { Money } from './gen/util/v1/money_pb';
import { LocalLedgerBackend } from './backend/ledger';

import { BsBullseye, BsClipboard2CheckFill, BsClipboardCheck, BsLink, BsLink45Deg, BsOpencollective } from 'react-icons/bs'
import { GetAllTransactions_Response } from './gen/ledger/v1/ledger_pb';
import moment from 'moment';

const transport = createConnectTransport({
  baseUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080',
  credentials: 'include',
})

export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
  // We memoize the client, so that we only create one instance per service.
  return useMemo(() => createPromiseClient(service, transport), [service]);
}

const TransactionBrandAllowList = new Set([
  'netflix',
  'spotify',
  'puregym',
  'pornhub',
  'disney plus',
  'sky',
  'hello fresh',
  'youtube',
  'gousto',
  'monzo',
  'now tv',
  'adobe',
  'deliveroo',
  'nandos',
  'google',
  'gsuite',
  'strava',
  'ovo energy',
  'pennypal',
  'icloud',
  'fastmail',
  '1password',
  'leetcode',
  'github',
  'waitrose',
  'amazon',
  'asda',
  'sainsburys',
  'lidl',
  'uber',
  'economist'
])

function getIconForCategory(category: string): React.ReactNode {
  return (({
    'subscription': <AiOutlineFileSync />,
    'shared': <AiOutlineSplitCells />,
    '': <AiOutlineExclamationCircle />,

    'essential': null,
    'health': <AiFillHeart />,
  } as any)[category.toLowerCase()]) || <AiOutlineSync />
}

const TransactionIcon = ({ metadata }: { metadata?: any }) => {
  const gradient = 'bg-gradient-to-r from-indigo-500 to-indigo-700'


  return (
    <div className='flex w-10 h-10'>
      <div className={`flex flex-col items-center w-10 h-10 rounded-xl shadow ${gradient} flex justify-around items-center`}>
        <span className='text-xl text-white self-center'>
          {
            TransactionBrandAllowList.has(metadata.brand) ? (
              // we have a pixel extra of room here to crop images rather than under-crop
              <div className="w-10 h-10 bg-contain bg-center rounded-xl" style={{ backgroundImage: `url('https://logo.clearbit.com/${metadata.brand.replace(' ', '')}.com')` }}></div>
            ) : (
              metadata.icon ? (
                metadata.icon
              ) : (
                getIconForCategory(metadata.category || '')
              )
            )
          }
        </span>
      </div>
    </div >
  )
}

const TransactionExpiration = ({ insertedAt, expiresAt }: { insertedAt?: Timestamp, expiresAt?: Duration }) => {
  const [now, setNow] = useState<any>(undefined)

  useEffect(() => {
    if (insertedAt && expiresAt) {
      const when = new Timestamp({
        seconds: insertedAt.seconds + expiresAt.seconds,
        nanos: 0,
      })

      setNow(when)
    }
  }, [insertedAt, expiresAt])

  if (!insertedAt) {
    return <></>
  }

  return (
    <>
      {now && <span className='text-xs'>
        Expires {moment(now.toDate()).fromNow()}
      </span>}
    </>
  )
}

// TransactionItemsView is a view onto a transactions individual columns
const TransactionItemsView = ({ tx, withAffordance, onClick }: { tx: Transaction, withAffordance?: boolean, onClick: any }) => {
  const localData = useLiveQuery(() =>
    db.local_data.get(process.env.NODE_ENV))

  const [selected, setSelected] = useState(false)

  if (!localData) {
    return (
      <p>Loading</p>
    )
  }

  return (
    <div
      className={`${withAffordance && 'hover:cursor-pointer'} py-1 flex flex-row justify-evenly gap-3 items-center`}>

      <div onClick={() => setSelected(!selected)} className='flex flex-col items-center'>
        {
          selected ? (
            <div className='flex flex-row justify-around w-full mt-1'>
              <div className='flex w-10 h-10 bg-black justify-around items-center rounded-xl shadow'>
                <input type="checkbox" className="checkbox" checked={true} />
              </div>
            </div>
          ) : (
            <div className='flex flex-row justify-around w-full mt-1'>
              <TransactionIcon metadata={{
                category: tx.category,
                title: tx.title,
                brand: (tx.title || '').toLowerCase(),
              }} />
            </div>
          )
        }
      </div>

      <div onClick={onClick} className='flex flex-col justify-around w-full'>
        <div className='flex flex-row gap-1'>
          <h2 className='text-lg font-bold mb-1 capitalize'>{tx.title}</h2>
          {((tx.additionalNote || '').length > 0) &&
            <div className="tooltip" data-tip={tx.additionalNote}>
              <span className='text-sm'><AiOutlineFileText /></span>
            </div>
          }
        </div>

        <div className='flex flex-row items-center gap-1'>
          <span className='badge badge-ghost capitalize badge-sm shadow-xs'>
            {tx.category || UNCATEGORISED_TRANS}
          </span>
          <TransactionExpiration insertedAt={tx.insertedAt} expiresAt={tx.expiresAt} />
        </div>

      </div>

      <div onClick={onClick} className='flex align-end'>
        {tx.amount &&
          <MoneyViewWithFrequency money={tx.amount} from={tx.frequency} to={localData?.currentViewingPeriod} />
        }
      </div>
    </div >
  )
}

const getCurrencySymbol = (code: string) => {
  return ({
    'GBP': '£',  // British Pound
    'USD': '$',  // US Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'CNY': '¥',  // Chinese Yuan
    'INR': '₹',  // Indian Rupee
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF',// Swiss Franc
    'NZD': 'NZ$',// New Zealand Dollar
    'ZAR': 'R',  // South African Rand
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'MXN': 'Mex$',// Mexican Peso
    'KRW': '₩',  // South Korean Won
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$',// Hong Kong Dollar
    'SEK': 'kr', // Swedish Krona
    'NOK': 'kr', // Norwegian Krone
    'DKK': 'kr', // Danish Krone
  })[code] || code
}

const calculateExpiratesAt = (tx: Transaction) => {
  if (tx.frequency == TransactionFrequency.ONE_OFF) {

    const endOfMonth = moment().endOf('month')
    const diff = moment(endOfMonth).diff(moment(), 'seconds', true)

    return new Duration({
      seconds: BigInt(Math.floor(diff)),
      nanos: 0,
    })
  }
  return undefined;
}

const TransactionWrapperView = ({ tx }: { tx: Transaction }) => {
  const [dirty, setDirty] = useState(false)

  const [modifiedTransaction, setModifiedTransaction] = useState<Transaction>(new Transaction({
    ...tx,
  }))

  const onChange = (e: any, field: string) => {
    setDirty(true)
    setModifiedTransaction((prev: any) => new Transaction({
      ...prev,
      [field]: e.target.value,
    }))
  }

  const handleSave = () => {
    db.transactions.update(modifiedTransaction.id, {
      ...modifiedTransaction,
      expiresAt: calculateExpiratesAt(modifiedTransaction),
    })
    db.commit()

    setDirty(false)
  }

  if (!tx || !modifiedTransaction) {
    return (
      <>
        Loading...
      </>
    )
  }

  const onClick = () => {
    (document.getElementById(`txn_modal_${tx.id}`) as any).showModal()
  }

  return (
    <div>
      <TransactionItemsView onClick={onClick} withAffordance tx={tx} />

      <dialog id={`txn_modal_${tx.id}`} className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <div className='my-4'>
            <div
              className={`rounded py-2 my-4 flex flex-row justify-evenly gap-4 items-center`}>

              <div className='flex mx-2 flex-col items-center'>
                <div className='flex flex-row justify-around w-full'>
                  <TransactionIcon metadata={{
                    category: tx.category,
                    title: tx.title,
                    brand: (tx.title || '').toLowerCase(),
                  }} />
                </div>
              </div>

              <div className='flex flex-col w-full'>
                <input
                  className='input input-text text-2xl font-bold capitalize'
                  value={modifiedTransaction.title}
                  onChange={(e) => {
                    setDirty(true)
                    setModifiedTransaction((prev: any) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }}
                  type='text' />
              </div>
            </div>

            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">What is the value of this expense?</span>
              </div>
              <div className='join flex flex-row w-full'>
                <label className="join-item grow input input-bordered flex items-center gap-2">
                  <span>{getCurrencySymbol(modifiedTransaction.amount?.code || '')}</span>
                  <input
                    className='grow'
                    type='number'
                    onChange={(e) => {
                      setDirty(true)
                      setModifiedTransaction((prev: any) => new Transaction({
                        ...prev,
                        amount: {
                          ...prev.amount,
                          value: parseFloat(e.target.value),
                        }
                      }))
                    }}
                    inputMode='decimal'
                    value={modifiedTransaction.amount?.value} />
                </label>
                <input type='text' className='w-1/4 input input-bordered input-text join-item'
                  onChange={(e) => {
                    setDirty(true)
                    setModifiedTransaction((prev: any) => new Transaction({
                      ...prev,
                      amount: {
                        ...prev.amount,
                        code: e.target.value,
                      }
                    }))
                  }}
                  inputMode='text'
                  value={modifiedTransaction.amount?.code}
                  placeholder='GBP' />
              </div>
            </label>

            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How often does this expense repeat?</span>
              </div>
              <select
                value={modifiedTransaction.frequency}
                onChange={(e: any) => {
                  setModifiedTransaction((prev: Transaction) => ({
                    ...prev,
                    frequency: parseInt(e.target.value) as TransactionFrequency,
                  } as Transaction))
                  setDirty(true)
                }}
                className="select select-bordered w-full capitalize">
                {
                  Object.entries(TransactionChoicesNamesMap)
                    .map(([key, val]) => <option className='capitalize' value={key} key={key}>{val}</option>)
                }
              </select>
            </label>

            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Categorise this expense, e.g. 'Essential'</span>
              </div>
              <input
                value={modifiedTransaction.category}
                onChange={(e) => onChange(e, 'category')}
                type='text'
                className='input input-bordered w-full' />
            </label>

            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Additional notes (optional):</span>
              </div>
              <textarea
                className='textarea textarea-bordered w-full'
                value={modifiedTransaction.additionalNote}
                onChange={(e) => onChange(e, 'additionalNote')}
                placeholder='Some additional notes about this transaction, e.g. "Cancel this in a month".'></textarea>
            </label>
          </div>

          <div className="modal-action">
            <form className='flex justify-between flex-row gap-2 w-full' method="dialog">
              <div className='flex flex-row gap-2'>
                <button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this expense?')) {
                      { db.deleteTransaction(tx.id) }
                    }
                  }}
                  className="btn btn-error text-white btn-sm rounded-2xl">
                  <span className='flex flex-row gap-1'><AiFillDelete /></span>
                </button>
              </div>

              <button
                disabled={!dirty}
                onClick={(e) => {
                  handleSave()
                }}
                className="btn btn-sm rounded-2xl text-white rounded btn-success">
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  )
}

const Widget = ({ title, children }: { title: any, children?: any }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div className='card my-2'>
      <div
        onClick={() => setCollapsed(!collapsed)}
        className='px-2 hover:cursor-pointer flex flex-row justify-between'>
        <h2 className='font-bold'>{title}</h2>

        <span className='flex flex-row text-xl items-center text-primary'>
          {collapsed ? <AiFillUpCircle /> : <AiFillDownCircle />}
        </span>
      </div>

      <div className={`px-2 overflow-hidden`}>
        {(children && !collapsed) && children}
      </div>
    </div>
  )
}

const CensoredMoneyValue = ({ money }: { money: Money }) => {
  const censored =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV)
      .then((r) => r?.censored || false))

  return (
    <>
      {censored ? 'xx.xx' : money.value.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </>
  )
}

const MoneyViewLitePlain = ({ money, bold = false, size = 'xl' }: { money: Money, bold?: boolean, size?: string }) => {
  return (
    <div className={`flex flex-row ${bold && 'font-bold'}`}>
      <span className={`text-${size}`}><span>{getCurrencySymbol(money.code)}</span><CensoredMoneyValue money={money} /></span>
    </div>
  )
}

const MoneyViewLite = ({ money, bold = false, size = 'xl' }: { money: Money, bold?: boolean, size?: string }) => {
  return (
    <div className={`flex flex-row ${bold && 'font-bold'}`}>
      <span className={`text-${size}`}><span>{getCurrencySymbol(money.code)}</span><CensoredMoneyValue money={money} /></span>
    </div>
  )
}

const normalizeMoney = (money: Money, from: TransactionFrequency, to: TransactionFrequency) => {
  return new Money({
    ...money,
    value: LocalLedgerBackend.normalize(money.value, from, to),
  } as Money)
}

const MoneyViewWithFrequency = ({ money, from, to }: { money: Money, from: TransactionFrequency, to: TransactionFrequency }) => {
  const [normalized, setNormalized] = useState(normalizeMoney(money, from, to))

  useEffect(() => {
    setNormalized(normalizeMoney(money, from, to))
  }, [money, from, to])

  return (
    <div className='flex flex-row gap-1 items-center'>
      <div className='flex flex-col items-end'>
        <MoneyView money={normalized} />
        {
          (normalized.value !== money.value) && (
            <span className='text-xs flex flex-row items-center'>
              (<MoneyViewLite size='xs' money={money} />)
            </span>
          )
        }
      </div>
    </div>
  )
}

const MoneyView = ({ money, bold = false, size = 'xl' }: { money: Money, bold?: boolean, size?: string }) => {
  const [converted, setConverted] = useState<number>(money.value || 0)

  const exchange_rates = useLiveQuery(() => db.client_data.get(process.env.NODE_ENV).then((r) => r?.exchange_rates || {}))

  const baseCurrencyCode =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV).then((r) => r?.primaryCurrencyCode)) || ''

  useEffect(() => {
    if (exchange_rates) {
      setConverted(money.value / exchange_rates[money.code])
    }
  }, [exchange_rates])

  return (
    <div className={`flex flex-col items-end ${bold && 'font-bold'}`}>
      <span className={`text-${size}`}>
        {
          baseCurrencyCode !== money.code ? (
            <div className="tooltip tooltip-bottom" data-tip={`≈ ${getCurrencySymbol(baseCurrencyCode)}${converted.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}>
              <span>{getCurrencySymbol(money.code)}</span><CensoredMoneyValue money={money} />
            </div>
          ) : (
            <>
              <span>{getCurrencySymbol(money.code)}</span><CensoredMoneyValue money={money} />
            </>
          )
        }
      </span>
    </div>
  )
}

const BigMoneyView = ({ money, bold = false }: { money: Money, bold?: boolean, size?: string }) => {
  return (
    <div className={`flex flex-col items-end ${bold && 'font-bold'}`}>
      <span className={`text-[38pt]`}>
        <span>{getCurrencySymbol(money.code)}</span><CensoredMoneyValue money={money} />
      </span>
    </div>
  )
}

const IncomeStreamItemView = ({ is }: { is: Income }) => {
  const withAffordance = true

  const handleIncomeItemClicked = () => {
    (document.getElementById(`is-${is.id}-modal`) as any).showModal()
  }

  return (
    <div
      onClick={handleIncomeItemClicked}
      className={`${withAffordance && 'hover:cursor-pointer'} py-1 flex flex-row justify-evenly gap-3 items-center`}>

      <div className='flex flex-col items-center'>
        <div className='flex flex-row justify-around w-full mt-1'>
          <TransactionIcon metadata={{
            icon: <AiFillSignal />
          }} />
        </div>
      </div>

      <div className='flex flex-col justify-around w-full'>
        <h2 className='text-lg font-bold mb-1 capitalize'>{is.name}</h2>
      </div>

      <div className='flex align-end'>
        <div className='flex flex-col'>
          {is.amount && <MoneyViewLite money={is.amount} />}
          <span className='self-end text-gray-600 text-xs italic'>
            {(TransactionChoicesNamesMapUnqualified as any)[is.payoutCadence]}
          </span>
        </div>
      </div>

      <dialog id={`is-${is.id}-modal`} className="modal">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <h3 className="font-bold text-lg"></h3>
          <div className='flex flex-col items-center gap-2'>
            <button
              disabled
              className='btn btn-success btn-sm text-white rounded-2xl'
              onClick={() => { }}>
              <span className='flex flex-row gap-1'>
                <AiFillSignature /> Register Increase / Decrease
              </span>
            </button>

            <button
              className='btn btn-error btn-sm text-white rounded-2xl'
              onClick={() => db.deleteIncomeStream(is.id)}>
              <span className='flex flex-row gap-1'>
                <AiFillSignature /> Delete Income
              </span>
            </button>
          </div>
        </div>
      </dialog>
    </div>
  )
}

const IncomeStreamView = () => {
  const incomeStreams = useLiveQuery(() => db.income_streams.toArray())

  if (!incomeStreams) {
    return (
      <p>Loading</p>
    )
  }

  return (
    <Widget title={<>Incoming</>}>
      <div className='flex flex-col gap-1'>
        {
          incomeStreams
            .map((is) => new Income(is))
            .sort((a, b) => (b.amount?.value || 0) - (a.amount?.value || 0))
            .map((is) => (
              <IncomeStreamItemView key={`is-${is.id}`} is={is} />
            ))
        }
      </div>
    </Widget>
  )
}

const CategoryBreakdownView = () => {
  const [categorySet, setCategorySet] = useState<any>([])
  const [categories, setCategories] = useState<any>({})

  const baseCurrencyCode =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV)
      .then((r) => r?.primaryCurrencyCode)) || ''

  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))

  useLiveQuery(() => {
    db.transactions
      .toArray()
      .then((items) => {
        setCategories({})

        const categories =
          items
            .map((tx: Transaction) => new Transaction(tx))
            .sort((a: Transaction, b: Transaction) => (b.amount?.value || 0) - (a.amount?.value || 0))
            .map((tx: Transaction) => tx.category)

        // @ts-ignore
        setCategorySet([...new Set(categories)])
      })
  })

  useEffect(() => {
    categorySet.map((category: string) => {
      Promise.resolve(db.allTransactionsForCategory(category))
        .then((result: any) => setCategories((prev: any) => ({
          ...prev,
          [category]: result
            .map((tx: Transaction) => normalizeMoney(new Money({ ...tx.amount }), tx.frequency, localData?.currentViewingPeriod || TransactionFrequency.MONTHLY).value)
            .reduce((prev: number, accum: number) => prev + accum, 0),
        })))
    })
  }, [categorySet])

  return (
    <main className='w-full md:flex md:flex-row md:w-2/3 lg:w-1/2 overflow-x-hidden'>
      {(Object.entries(categories || {}).length > 0) && <div className='w-100'>
        <div className='px-2'>
          <h2 className='font-bold'>Overview</h2>
        </div>

        <div>
          <div className='flex flex-row mx-2 overflow-x-scroll no-scrollbar gap-2 w-100'>
            {
              Object.entries(categories)
                .sort((a: any, b: any) => b[1] - a[1])
                .map(([category, value]: any) => {
                  return <CategoryNugget key={category}>
                    <span className='text-sm text-gray-200 capitalize font-bold'>{category || UNCATEGORISED_TRANS}</span>
                    <span className='text-xl font-bold'>
                      <MoneyViewLite money={new Money({
                        value: value,
                        code: baseCurrencyCode,
                      })} />
                    </span>
                  </CategoryNugget>
                })
            }
          </div>
        </div>
      </div>}
    </main>
  )
}

const UNCATEGORISED_TRANS = 'Uncategorised'

const UncategorisedView = () => {
  const uncategorisedTransactions = useLiveQuery(() => db.transactions
    .where('category')
    .equalsIgnoreCase('')
    .toArray())

  if (!uncategorisedTransactions) {
    return <>Loading.</>
  }

  return (
    <>
      {
        (uncategorisedTransactions || []).length !== 0 &&
        <Widget title={`Uncategorised (${uncategorisedTransactions.length})`}>
          <div>
            {
              uncategorisedTransactions
                .map((tx: Transaction) => new Transaction(tx))
                .sort((a: Transaction, b: Transaction) => (b.amount?.value || 0) - (a.amount?.value || 0))
                .map((tx: any) => <TransactionWrapperView key={tx.id} tx={tx} />)
            }
          </div>
        </Widget>
      }
    </>
  )
}

const TransactionsListView = ({ categoryFilter }: { categoryFilter?: string }) => {
  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))

  const transactions = useLiveQuery(() => db.transactions
    .where('category')
    .notEqual('')
    .toArray())
  if (!transactions) {
    return <>Loading.</>
  }

  return (
    <>
      <Widget title={`Expenses`}>
        <div>
          {
            transactions
              .map((tx: Transaction) => new Transaction(tx))
              .sort((a: Transaction, b: Transaction) => {
                const aNorm = normalizeMoney(new Money(a.amount), a.frequency, localData?.currentViewingPeriod || TransactionFrequency.MONTHLY)
                const bNorm = normalizeMoney(new Money(b.amount), b.frequency, localData?.currentViewingPeriod || TransactionFrequency.MONTHLY)
                return bNorm.value - aNorm.value
              })
              .filter((tx: Transaction) => {
                if (categoryFilter) {
                  return tx.category == categoryFilter
                }
                return true;
              })
              .map((tx: any) => <TransactionWrapperView key={tx.id} tx={tx} />)
          }
        </div>
      </Widget>
    </>
  )
}

const TransactionChoicesNamesMap = {
  [TransactionFrequency.ONE_OFF]: 'once',
  [TransactionFrequency.DAILY]: 'every day',
  [TransactionFrequency.WEEKLY]: 'every week',
  [TransactionFrequency.MONTHLY]: 'every month',
  [TransactionFrequency.QUARTERLY]: 'every quarter',
  [TransactionFrequency.YEARLY]: 'every year',
}

const TransactionChoicesNamesMapUnqualified: any = {
  [TransactionFrequency.ONE_OFF]: 'once',
  [TransactionFrequency.DAILY]: 'daily',
  [TransactionFrequency.WEEKLY]: 'weekly',
  [TransactionFrequency.MONTHLY]: 'monthly',
  [TransactionFrequency.QUARTERLY]: 'quarterly',
  [TransactionFrequency.YEARLY]: 'yearly',
}

const UnqualifiedBreakdownPeriod: any = {
  [TransactionFrequency.DAILY]: 'daily',
  [TransactionFrequency.WEEKLY]: 'weekly',
  [TransactionFrequency.MONTHLY]: 'monthly',
  [TransactionFrequency.QUARTERLY]: 'quarterly',
  [TransactionFrequency.YEARLY]: 'yearly',
}

const RestoreFromCloudModal = ({ setSynchronizing, fetchLatestFromCloud }: { setSynchronizing: any, fetchLatestFromCloud: any }) => {
  return (
    <div className="modal-box">
      <h3 className="font-bold text-lg">Local device out of date</h3>
      <p className="py-4">
        Your local device seems to be out of date. Would you like to download all changes from the cloud?
      </p>
      <p>
        Note that by doing this you will completely overwrite your local changes.
      </p>

      <div className='flex flex-row justify-around my-6'>
        <form method="dialog">
          <button onClick={fetchLatestFromCloud} className='btn btn-success text-white shadow btn-sm rounded-2xl'>
            Sync latest from Cloud
          </button>
        </form>
      </div>
    </div>
  )
}

const SyncStatusModal = ({ handleSyncToCloud, handleRestoreFromCloud }: any) => {
  return (
    <div className="modal-box">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <div className="modal-action">
        <form className='flex flex-row justify-end w-full' method="dialog">
          <div className='flex flex-col'>
            <div>
              <h2 className='text-2xl font-bold'>Sync to Cloud</h2>
              <p>
                Don't lose your hard work! Make sure you regularly
                sync to the cloud.
              </p>
            </div>

            <div className='flex flex-col justify-around items-center my-4'>
              <div className='flex flex-col gap-4 w-2/3 my-4'>
                <button
                  onClick={handleSyncToCloud}
                  className='text-white btn btn-sm w-full btn-success rounded-2xl'>
                  Sync to Cloud
                </button>

                <div className='flex flex-row justify-around'>
                  <button
                    onClick={handleRestoreFromCloud}
                    className='btn btn-xs btn-error text-white rounded-2xl'>
                    <span className='flex flex-row items-center gap-1'>
                      <AiOutlineWarning /> Restore from Cloud
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const LocalPrefsModal = () => {
  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))

  const [modifiedLocalData, setModifiedLocalData] = useState<any>({})
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (localData) {
      setModifiedLocalData({
        ...localData,
      } as LocalData)
    }
  }, [localData])

  const handleSave = () => {
    setDirty(false)
    db.local_data.put({
      ...modifiedLocalData,
    } as LocalData)
    db.commit()
  }

  if (!localData) {
    return (
      <>
      </>
    )
  }

  return (
    <div className="modal-box">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <div className='flex flex-col'>
        <div>
          <h2 className='font-bold text-2xl'>Preferences</h2>
        </div>

        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Censor values</span>
            <input
              type="checkbox"
              className="toggle"
              onChange={(e) => {
                setDirty(true)
                setModifiedLocalData((prev: any) => ({
                  ...prev,
                  censored: e.target.checked,
                }))
              }}
              checked={modifiedLocalData.censored} />
          </label>
        </div>

        <div className='form-control'>
          <label className="label cursor-pointer">
            <span className="label-text">Breakdown View</span>

            <select
              value={modifiedLocalData.currentViewingPeriod}
              onChange={(e: any) => {
                setDirty(true)
                setModifiedLocalData((prev: any) => ({
                  ...prev,
                  currentViewingPeriod: parseInt(e.target.value) as TransactionFrequency, // HACKY
                }))
              }}
              className="select select-bordered select-sm capitalize">
              {
                Object.entries(UnqualifiedBreakdownPeriod)
                  .map(([key, val]) => <option value={key} key={key} className='capitalize'>{val as string}</option>)
              }
            </select>
          </label>
        </div>

        <div>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">What is your preferred currency code? (e.g. GBP)</span>
            </div>

            <input
              placeholder='GBP'
              value={modifiedLocalData?.primaryCurrencyCode}
              onChange={(e: any) => {
                setDirty(true)
                setModifiedLocalData((prev: any) => ({
                  ...prev,
                  primaryCurrencyCode: e.target.value,
                }))
              }}
              className='input input-text input-bordered'
              inputMode='text'
              type='text' />
          </label>
        </div>

        <form method='dialog'>
          <div className='flex flex-row justify-end my-4'>
            <button
              disabled={!dirty}
              onClick={handleSave} className="btn btn text-white rounded-2xl btn-sm btn-success">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const RegisterIncomeModal = () => {
  const [dirty, setDirty] = useState(false)

  const baseCurrencyCode =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV)
      .then((r) => r?.primaryCurrencyCode)) || ''

  const [newIncomeStream, setNewIncomeStream] = useState<Income>(new Income({
    id: uuidv4(),
    name: undefined,
    insertedAt: Timestamp.now(),
    payoutCadence: TransactionFrequency.MONTHLY,
    amount: {
      ...(new Money()),
      code: baseCurrencyCode,
    }
  }))

  const submitIncomeStream = () => {
    db.income_streams.add(newIncomeStream, newIncomeStream.id)
      .then(() => {
        setDirty(false)
        setNewIncomeStream(new Income({
          id: uuidv4(),
          name: undefined,
          insertedAt: Timestamp.now(),
          payoutCadence: TransactionFrequency.MONTHLY,
          amount: {
            ...(new Money()),
            code: baseCurrencyCode,
          }
        }))
      })
    db.commit()
  }

  const handleChange = (e: any, field: string) => {
    setDirty(true)
    setNewIncomeStream((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }))
  }

  return (
    <div className="modal-box">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <div className='flex flex-col w-full'>
        <div>
          <h2 className='my-2 font-bold text-2xl'>Register an income stream</h2>
        </div>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Set a memorable title for your income</span>
          </div>
          <input
            value={newIncomeStream.name}
            placeholder='Salary'
            onChange={(e) => handleChange(e, 'name')}
            className='input input-bordered' type='text' />
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">How much is your income stream?</span>
          </div>
          <div className='flex flex-row join w-full'>
            <label
              className="grow input join-item input-bordered flex items-center gap-2">
              <span>{getCurrencySymbol(newIncomeStream.amount?.code || '')}</span>
              <input
                placeholder='1200'
                value={newIncomeStream.amount?.value}
                onChange={(e: any) => {
                  setDirty(true)
                  setNewIncomeStream((prev: any) => ({
                    ...prev,
                    amount: {
                      ...prev.amount,
                      value: parseFloat(e.target.value)
                    }
                  }))
                }}
                inputMode='decimal' type='number' />
            </label>
            <input type='text'
              className='input w-1/4 input-bordered input-text join-item'
              onChange={(e) => {
                setDirty(true)
                setNewIncomeStream((prev: any) => ({
                  ...prev,
                  amount: {
                    ...prev.amount,
                    code: e.target.value,
                  }
                }))
              }}
              inputMode='text'
              value={newIncomeStream.amount?.code}
              placeholder='GBP' />
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">How often does this income pay out?</span>
          </div>
          <select
            value={newIncomeStream.payoutCadence}
            onChange={(e: any) => {
              setNewIncomeStream((prev: any) => ({
                ...prev,
                payoutCadence: parseInt(e.target.value) as TransactionFrequency,
              }))
              setDirty(true)
            }}
            className="select select-bordered capitalize">
            {
              Object.entries(TransactionChoicesNamesMap)
                .map(([key, val]) => <option value={key} key={key} className='capitalize'>{val}</option>)
            }
          </select>
        </label>
      </div>

      <div className="modal-action">
        <form className='flex flex-row justify-end w-full' method="dialog">
          <button
            className="btn btn-success text-white btn-md rounded"
            onClick={(e) => submitIncomeStream()}>
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

const Tooltip = ({ children }: any) => {
  return (
    <p className='text-xs text-gray-600 italic my-2'>
      {children}
    </p>
  )
}

const CustomizeHomeModal = () => {
  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))

  const [dirty, setDirty] = useState(false)
  const [modifiedLocalData, setModifiedLocalData] = useState(localData)

  useEffect(() => {
    setModifiedLocalData(new LocalData({
      ...localData,
    }))
  }, [localData])

  if (!localData || !modifiedLocalData) {
    return (
      <>Loading</>
    )
  }

  return (
    <div className="modal-box">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <div className="flex flex-col modal-action">
        <h2 className='my-2 font-bold text-2xl'>Customise widgets</h2>

        <div className='flex flex-col w-full gap-2 my-4'>
          {
            Object.entries(modifiedLocalData.widgetConfig || {})
              .map(([twine, config]) => (
                <div key={twine} className='flex flex-row justify-between items-center w-full'>
                  <h3 className={`font-bold text-lg capitalize ${!config.visible && 'text-gray-400 '}`}>{twine.replace('_', ' ')}</h3>

                  <button onClick={() => {
                    setDirty(true)
                    setModifiedLocalData((prev: any) => ({
                      ...prev,
                      widgetConfig: {
                        ...prev.widgetConfig,
                        [config.twine]: {
                          ...prev.widgetConfig[config.twine],
                          visible: !prev.widgetConfig[config.twine].visible,
                        }
                      }
                    }))
                  }} className='btn btn-sm btn-circle'>
                    {modifiedLocalData.widgetConfig[config.twine].visible ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                </div>
              ))
          }
        </div>

        <div className='flex flex-row w-full justify-end'>
          <form method="dialog">
            {
              dirty ?
                <button onClick={() => {
                  db.local_data.put(new LocalData({
                    ...modifiedLocalData,
                  }), process.env.NODE_ENV)
                  db.commit()
                }} className="btn btn-sm btn-success btn-sm rounded-2xl text-white">Save Changes</button> :
                <button className="btn btn-sm btn-sm rounded-2xl">Done</button>
            }
          </form>
        </div>
      </div>
    </div>
  )
}

const TransactionInputModal = () => {
  const baseCurrencyCode =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV)
      .then((r) => r?.primaryCurrencyCode)) || ''

  const [lineItemNames, setLineItemNames] = useState<string>('')

  const submitTransaction = () => {
    (lineItemNames || '')
      .split('.')
      .map((lineItem: string) => new Transaction({
        id: uuidv4(),
        title: lineItem.trim(),
        amount: new Amount({
          value: 0,
          code: baseCurrencyCode,
          unitType: UnitType.CURRENCY,
        }),
        frequency: TransactionFrequency.MONTHLY,
        insertedAt: Timestamp.now(),
      }))
      .forEach((tx: Transaction) => {
        db.transactions.add(tx, tx.id)
      })
    setLineItemNames('')
    db.commit()
  }

  const randomPlaceholder = () => {
    return 'Netflix'
  }

  return (
    <div className="modal-box">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <div className='flex flex-col'>
        <div>
          <h2 className='my-2 font-bold text-2xl'>Register an expense</h2>

          <div>
            <input
              value={lineItemNames}
              onChange={(e: any) => setLineItemNames(e.target.value)}
              className='input input-bordered w-full' type='text' placeholder={randomPlaceholder()} />

            <Tooltip>
              Pro-tip: separate your entries with a full stop to register multiple line-items at once, e.g. netflix. rent. electric
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="modal-action">
        <form className='flex flex-row justify-end w-full' method="dialog">
          <button
            className="btn btn-success text-white btn-sm rounded-2xl"
            onClick={submitTransaction}>
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

const AccountBalance = () => {
  const [total, setTotal] = useState({
    frequency: TransactionFrequency.MONTHLY,
    total: 0.0,
  })

  const transactions = useLiveQuery(() => db.transactions.toArray())
  const incomes = useLiveQuery(() => db.income_streams.toArray())
  const [balanceColour, setBalanceColour] = useState<any>()

  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))
  const exchange_rates = useLiveQuery(() => db.client_data.get(process.env.NODE_ENV).then((r) => r?.exchange_rates || {}))

  const baseCurrencyCode =
    useLiveQuery(() => db.local_data.get(process.env.NODE_ENV)
      .then((r) => r?.primaryCurrencyCode)) || ''

  useEffect(() => {
    if (transactions && incomes && exchange_rates && localData && baseCurrencyCode) {
      
      const excess = LocalLedgerBackend.calculateExcess(
        baseCurrencyCode,
        exchange_rates,
        incomes.map((is) => new Income(is)),
        transactions.map((tx) => new Transaction(tx)),
        Timestamp.now(),
        localData?.currentViewingPeriod || TransactionFrequency.MONTHLY);

      setTotal({
        total: excess,
        frequency: localData?.currentViewingPeriod || TransactionFrequency.MONTHLY,
      })

      setBalanceColour(excess)
    }

  }, [transactions, incomes, localData])

  return (
    <div className={`badge font-bold mx-2 ${balanceColour < 0 ? 'badge-error text-white' : 'text-white badge-success'}`}>
      {
        (total && localData) && (
          <MoneyViewWithFrequency
            money={new Money({
              value: total.total,
              code: baseCurrencyCode,
            })}
            from={total.frequency}
            to={localData?.currentViewingPeriod || TransactionFrequency.MONTHLY} />
        )
      }
    </div>
  )
}

const Overview = () => {
  const [glint, setGlint] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setGlint(true)
      setTimeout(() => {
        setGlint(false)
      }, 2000)
    }, 7500)
  }, [])

  const handleLogTransaction = () => {
    (document.getElementById('log_txn_modal') as any).showModal()
  }

  const handleRegisterIncoming = () => {
    (document.getElementById('register_income_modal') as any).showModal()
  }

  return (
    <>
      <div className='mx-2 mt-2 my-2'>
        <div className='flex flex-row justify-around items-center'>
          <div className='flex flex-row items-center justify-evenly gap-4 my-3'>
          </div>
        </div>

        <div className='flex flex-row'>
          <div className='flex flex-col gap-2 justify-around w-full'>
            <div className='flex flex-row items-center gap-2'>
              <button
                className='btn btn-success rounded-2xl btn-sm text-white rounded'
                onClick={handleLogTransaction}>
                <span className='flex flex-row gap-1'>
                  <AiFillSignature /> Submit expense
                </span>
              </button>

              <button
                className='btn btn-default btn-sm rounded-2xl'
                onClick={handleRegisterIncoming}>
                <span className='flex flex-row gap-1 ease-in duration duration-800 hover:ease-in ease-in-out'>
                  <AiFillBank /> Register income
                </span>
              </button>

              <button
                className={`${glint && 'animate-pulse'} duration-500 btn rounded-2xl text-white btn-sm btn-primary`}
                onClick={() => (window.open('https://forms.gle/6ErW8q2Vmg44EEmG6', '_blank'))}>
                <span className='flex flex-row items-center gap-1'><BsClipboard2CheckFill /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CategoryNugget = ({ children, onClick }: { children?: any, onClick?: any }) => {
  return (
    <div className={`my-2 ${onClick && 'hover:cursor-pointer'}`}>
      <div className={`flex flex-row w-32 h-24 bg-gradient-to-b from-indigo-500 to-indigo-600 rounded-xl shadow text-white`}>

        <div onClick={onClick} className='flex flex-col self-end w-full m-2 py-2'>
          {children && children}
        </div>
      </div>
    </div>
  )
}

const showUpcoming = false

const UpcomingTransactions = () => {
  const transactions = useLiveQuery(() => db.transactions.toArray())

  if (!transactions) {
    return <>Loading.</>
  }

  return (
    <Widget title='Upcoming Transactions'>
      <div>
        {
          transactions
            .map((tx: Transaction) => new Transaction(tx))
            .filter((tx: Transaction) => {
              return true;
            })
            .slice(0, 3)
            .map((tx: any) => <TransactionWrapperView tx={tx} />)
        }
      </div>
    </Widget>
  )
}

enum SyncStatus {
  UP_TO_DATE = 'IN SYNC',
  OUT_OF_DATE = 'UNSAVED_CHANGES'
}

const filters: any = {
  'title': (a: Transaction, b: Transaction) => {
    return a && b && (a.title < b.title)
  },
}

const TableView = () => {
  const transactions = useLiveQuery(() => db.transactions.toArray())


  const [activeFilter, setActiveFilter] = useState<any>(filters['title'])

  if (!transactions) {
    return <>
      Loading.
    </>
  }

  const exportCSV = () => {
    alert('This is a WIP!')
  }

  return (
    <Widget title='Sheet View'>
      <div>
        <div className="overflow-x-auto h-64">
          <table className="table table-pin-cols table-pin-rows table-xs">
            <thead>
              <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Amount</th>
                <th>Frequency</th>
              </tr>
            </thead>
            <tbody>
              {
                transactions
                  .map((tx) => new Transaction(tx))
                  .sort((a, b) => (b?.amount?.value || 0) - (a?.amount?.value || 0))
                  .map((tx, index) => (
                    <tr key={tx.id}>
                      <td className='capitalize'>{tx.title}</td>
                      <td className='capitalize'>{tx.category}</td>
                      <td><MoneyViewLite size='md' money={new Money({ ...tx.amount })} /></td>
                      <td className='capitalize'>{TransactionChoicesNamesMapUnqualified[tx.frequency]}</td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
      </div>
      {false && <div className='flex flex-row justify-end'>
        <p onClick={exportCSV} className='text-blue-500 hover:cursor-pointer hover:underline text-xs'>
          Export as CSV
        </p>
      </div>}
    </Widget>
  )
}

const is_visible = (localData: LocalData, twine: string) => {
  if (!Object.hasOwn(localData?.widgetConfig || {}, twine)) {
    return false
  }
  return localData.widgetConfig[twine].visible
}

const Home = () => {
  const localData = useLiveQuery(() => db.local_data.get(process.env.NODE_ENV))

  const txCount = useLiveQuery(() => db.transactions.toArray())

  const [hasBlankAccount, setHasBlankAccount] = useState(true)

  const widgetElMap: Record<string, any> = {
    'overview': <CategoryBreakdownView />,
    'expenses_view': <main className='w-full md:w-2/3 lg:w-1/2'><TransactionsListView /></main>,
    'income_streams': <main className='w-full md:w-2/3 lg:w-1/2'><IncomeStreamView /></main>,
    'table_view': <main className='w-full md:w-2/3 lg:w-1/2'><TableView /></main>
  }

  useEffect(() => {
    if (txCount) {
      setHasBlankAccount(txCount.length == 0)
    }
  }, [txCount])

  if (!localData) {
    return <>
      Loading!
    </>
  }

  return (
    <>
      <div className='my-4' />

      {
        !hasBlankAccount && (
          <main className='w-full md:w-2/3 lg:w-1/2'>
            <Overview />
          </main>
        )
      }

      <main className='w-full md:w-2/3 lg:w-1/2'>
        <UncategorisedView />
      </main>

      {
        !hasBlankAccount ? (
          <>
            {
              Object.values(localData.widgetConfig)
                .sort((a, b) => a.position - b.position)
                .map((config: WidgetConfig) => (
                  <React.Fragment key={config.twine}>
                    {
                      // @ts-ignore
                      is_visible(localData, config.twine) && widgetElMap[config.twine]
                    }
                  </React.Fragment>
                ))
            }

            <main className='w-full md:w-2/3 lg:w-1/2 my-4'>
              <div className='flex flex-row justify-around w-100'>
                <div className='flex flex-row gap-2'>
                  <button
                    onClick={() => (document.getElementById('customize_home_modal') as any).showModal()}
                    className="btn btn-primary btn-ghost btn-sm rounded-2xl">
                    <span className='flex flex-row gap-1'><AiFillFormatPainter /> Customise</span>
                  </button>
                </div>
              </div>
            </main>
          </>
        ) : (
          <div className='flex grow flex-row w-full justify-around'>
            <div className='flex flex-col justify-around items-center my-8'>
              <div className='flex flex-col items-center justify-around'>
                <h3 className='text-2xl font-bold my-2'>Nothing to see here</h3>
                <p>
                  Why not get started by adding an expense or two?
                </p>
              </div>

              <div>
                <button
                  className='btn btn-success rounded-2xl btn-sm text-white rounded'
                  onClick={() => {
                    (document.getElementById('log_txn_modal') as any).showModal()
                  }}>
                  <span className='flex flex-row gap-1'>
                    <AiFillSignature /> Submit expense
                  </span>
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

const iconForStatus = (status: SyncStatus) => {
  const d = {
    [SyncStatus.OUT_OF_DATE]: <AiOutlineWarning />,
    [SyncStatus.UP_TO_DATE]: <AiFillCheckCircle />
  } as any
  return d[status]
}

const Navbar = () => {
  const client_version = useLiveQuery(() => db.client_data.get(process.env.NODE_ENV).then((res) => res?.version))
  const exchange_rates = useLiveQuery(() => db.client_data.get(process.env.NODE_ENV).then((res) => res?.exchange_rates))

  const [status, setStatus] = useState(SyncStatus.UP_TO_DATE)

  const { user } = useUser()
  const client = useClient(LedgerService)
  const { getToken } = useAuth()

  // every time the client-data version changes
  // we want to ensure we're in sync.
  useEffect(() => {
    if (client_version) {
      checkSync()
    }
  }, [client_version])

  const [syncing, setSyncing] = useState(false)
  const [handler, setHandler] = useState<any>(undefined)

  const checkSync = async () => {
    if (!user) {
      return
    }

    const remote_version = await client.getAccountVersion({}, {
      headers: {
        'Authorization': `Bearer ${await getToken()}`
      }
    })
      .then((resp) => resp.version)

    // check version on local
    const local_version = (await db.client_data.get(process.env.NODE_ENV))?.version || 0

    if (local_version === undefined && !syncing) {
      (document.getElementById('restore_from_cloud') as any).showModal()
      setStatus(SyncStatus.OUT_OF_DATE)
    }
    else if (BigInt(local_version) == remote_version) {
      setStatus(SyncStatus.UP_TO_DATE)
    } else {
      setStatus(SyncStatus.OUT_OF_DATE)
    }
  }

  useEffect(() => {
    if (exchange_rates && Object.entries(exchange_rates).length > 0) {
      return
    }

    Promise.resolve(client.convertCurrency({
      baseCurrencyCode: 'GBP',

      // for now we pre-compute all supported currencies.
      targetCurrencyCode: [
        'GBP', 'USD', 'EUR', 'JPY', 'AUD', 'CAD', 'SGD'
      ],
    })).then((resp) => {
      // FIXME(FELIX): race condition here?

      db.client_data.put({
        exchange_rates: resp.rates,
        env: process.env.NODE_ENV,
        version: BigInt(client_version || 0),
      }, process.env.NODE_ENV)
    })

  }, [exchange_rates])

  useEffect(() => {
    if (handler) {
      clearInterval(handler)
    }

    // we only register this sync handler
    // if we have a user present.
    if (user) {
      // we always want to immediately check for a sync on page load.
      checkSync()

      // ... otherwise we schedule checks every 10s.
      const intervalHandler = setInterval(() => {
        checkSync()
      }, 30_000)
      setHandler(intervalHandler)
    }
  }, [user])

  const fetchLatestFromCloud = async () => {
    setSyncing(true)

    client.getAllTransactions({}, {
      headers: {
        'Authorization': `Bearer ${await getToken()}`
      }
    }).then((resp: GetAllTransactions_Response) => {
      db.transactions.bulkPut(resp.tx)
      db.income_streams.bulkPut(resp.is)

      if (resp.localData) {
        db.local_data.put(resp.localData, process.env.NODE_ENV)
      }

      db.commit(resp.latestVersion)
      setSyncing(false)
    })
  }

  const handleCloudSync = async () => {
    setSyncing(true)
    const tx = await db.transactions.toArray()
    const is = await db.income_streams.toArray()

    const ld = await db.local_data.get(process.env.NODE_ENV);
    if (!ld) {
      throw new Error('something bad happened')
    }

    client.putAllTransactions({
      tx: tx,
      is: is,
      localData: ld,
    }, {
      headers: {
        'Authorization': `Bearer ${await getToken()}`
      }
    }).then((resp) => {
      db.commit(resp.latestVersion)
      setSyncing(false)
    })
  }

  return (
    <>
      <div className="navbar fixed shadow bg-white z-10">
        <SignedIn>
          <div className="flex-1 gap-1">


            <div className="dropdown dropdown-start">

              <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                  <img alt="Tailwind CSS Navbar component" src={user?.imageUrl} />
                </div>
              </div>

              <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
                <li>
                  <a onClick={() => (document.getElementById('local_prefs_modal') as any).showModal()}>
                    Preferences
                  </a>
                </li>

                <li>
                  <a onClick={() => (window.open('https://accounts.pennypal.app/user', '_blank'))}>Manage Account</a>
                </li>

                <li className='text-error'>
                  <SignOutButton>
                    Log Out
                  </SignOutButton>
                </li>
              </ul>
            </div>

            <div>
              <AccountBalance />
            </div>
          </div>
        </SignedIn>

        <SignedIn>
          <div className="mr-2">
            <div className='join'>
              {
                !syncing ? (
                  <button
                    onClick={() => (document.getElementById('sync_status_modal') as any).showModal()}
                    className={`btn ${status === SyncStatus.UP_TO_DATE ? 'btn-success text-white' : 'btn-warning text-black'} rounded-full btn-xs`}>
                    {iconForStatus(status)} {(status).replaceAll('_', ' ')}
                  </button>
                ) : (
                  <button
                    disabled
                    className={`btn ${status === SyncStatus.UP_TO_DATE ? 'btn-success text-white' : 'btn-warning text-black'} rounded-full btn-xs`}>
                    <span>Loading</span>
                  </button>
                )
              }
            </div>
          </div>
        </SignedIn>
      </div>

      <dialog id='sync_status_modal' className='modal modal-bottom sm:modal-middle'>
        <SyncStatusModal
          handleRestoreFromCloud={fetchLatestFromCloud}
          handleSyncToCloud={handleCloudSync} />
      </dialog>

      <dialog id="restore_from_cloud" className="modal modal-bottom sm:modal-middle">
        <RestoreFromCloudModal fetchLatestFromCloud={fetchLatestFromCloud} setSynchronizing={setSyncing} />
      </dialog>
    </>
  )
}

const Footer = () => {
  return (
    <footer style={{ fontSize: '12pt' }} className="relative bottom-0 footer bg-gray-50 mt-8 mt-8 text-gray-600 p-10">
      <aside>
        <div className='flex flex-row gap-1 items-center'>
          <span>🏦</span>
          <p className="font-bold">
            PennyPal
          </p>
        </div>
        <p>© {new Date().getFullYear()} <a href='https://yarefs.net'>Yarefs LTD</a>. All right reserved.</p>
        <div className='text-xs flex flex-row items-center gap-2'>
          <p className='text-xs'>
            <a href='https://albms.net/privacy.html'>Privacy Policy</a>
          </p>
        </div>
        <a className='text-gray-300' href="https://clearbit.com">Logos provided by Clearbit</a>
      </aside>
    </footer>
  )
}

const SignedOutView = () => {
  return (
    <div>
      <>
        <main>
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "33vh"
            }}>
            <div className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient-to-r from-blue-600 to-violet-600">
              <span id="blackOverlay" className="w-full h-full absolute"></span>
            </div>
            <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                    <h1 className="text-white font-semibold text-5xl">
                      PennyPal.
                    </h1>
                    <p className="my-4 text-lg text-white leading-relaxed">
                      Say goodbye to spreadsheets and hello to PennyPal, the finance app designed for simplicity and effectiveness. Realize your financial vision effortlessly.
                    </p>

                    <div className='my-2'>
                      <SignInButton>
                        <button
                          className='btn btn-success rounded-2xl btn text-white rounded'>
                          CREATE AN ACCOUNT &mdash; IT'S FREE!
                        </button>
                      </SignInButton>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
              style={{ height: "70px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-300 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </div>

          <section className="relative py-20">
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
              style={{ height: "80px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-white fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">
                <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                  <img
                    alt="..."
                    className="max-w-full rounded-lg"
                    src="https://i.imgur.com/CAD3q0e.png"
                  />
                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                  <div className="md:pr-12">
                    <div className="text-pink-600 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300 text-2xl">
                      <img className='rounded-full' src='/logo192.png' />
                    </div>
                    <h3 className="text-3xl font-semibold">
                      Why PennyPal?
                    </h3>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                      PennyPal is a privacy-focused, simple, yet ambitious tool for managing your finances. We plan to operate around the following key tenets:
                    </p>
                    <ul className="list-none mt-6">
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                              <AiFillMoneyCollect />
                            </span>
                          </div>
                          <div>
                            <h4 className="text-gray-600">Managing finances should be inexpensive &mdash; we aim to offer a competitive price against alternative software.</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                              <AiFillSafetyCertificate />
                            </span>
                          </div>
                          <div>
                            <h4 className="text-gray-600">
                              We care about your privacy &mdash; all data is local to your device with an optional cloud-sync feature. We will never sell information to a third-party.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                              <AiFillHeart />
                            </span>
                          </div>
                          <div>
                            <h4 className="text-gray-600">
                              We are committed to evolving with our customers &mdash; your feedback directly shapes the development of PennyPal.
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="pt-20 pb-48">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap justify-center text-center mb-24">
                <div className="w-full lg:w-6/12 px-4">
                  <h2 className="text-4xl font-semibold">
                    Take control of your finances today
                  </h2>
                  <p className="text-lg leading-relaxed m-4 text-gray-600">
                    We're still working out a few things. <span className='font-bold'>Until then PennyPal is available at no cost!</span> We will eventually bring in a plan where you can opt-in to premium features at a small monthly or yearly fee. Life-time plans will also be available.
                  </p>
                </div>
              </div>

              <div className='flex flex-row justify-around w-full'>
                <SignInButton>
                  <button
                    className='btn btn-success rounded-2xl btn text-white rounded'>
                    CREATE AN ACCOUNT &mdash; IT'S FREE!
                  </button>
                </SignInButton>
              </div>
            </div>
          </section>

          <section className="pb-20 relative block bg-gray-900">
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
              style={{ height: "80px" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-900 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
              <div className="flex flex-wrap text-center justify-center">
                <div className="w-full lg:w-6/12 px-4">
                  <h2 className="text-4xl font-semibold text-white">
                    What's next?
                  </h2>
                  <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-200">
                    PennyPal is in early development &mdash; it is far from perfect. Here are some of the things you can hope to expect in the future.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap mt-12 justify-center">
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <AiFillCloud />
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-white">
                    On-Device Data
                  </h6>
                  <p className="mt-2 mb-4 text-gray-200 font-bold">
                    All data is kept on your personal device. You can sync across the cloud with our sync functionality.
                  </p>
                  <p className="mt-2 mb-4 text-gray-200 items-center">
                    🎯 We plan to build an option for manual exports to .csv which you can manually backup and save on your personal cloud for peace of mind.
                  </p>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <AiFillMobile />
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    Mobile Friendly
                  </h5>
                  <p className="mt-2 mb-4 text-gray-200 font-bold">
                    If you miss the spreadsheet view, why not have both?<br />PennyPal breaks down your incomings and outgoings in a clean user-interface viewable on all devices - but also exposes this information in a spreadsheet-like view.
                  </p>
                  <p className="mt-2 mb-4 text-gray-200">
                    🎯 We plan to explore native applications for all major mobile platforms. Until then, you can manually add the application to your home-screen.
                  </p>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <AiFillBank />
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    Transaction Import
                  </h5>
                  <p className="mt-2 mb-4 text-gray-200 font-bold">
                    Right now there is a lot of focus on manual data-entry.
                  </p>
                  <p className="mt-2 mb-4 text-gray-200">
                    🎯 We plan to allow for importing transactional data from your bank account. In addition to this, we would like to some day allow for live-data synchronisation between your bank accounts with PennyPal.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </div>
  )
}

function App() {
  return (
    <>
      <SignedIn>
        <Navbar />
        <div className="flex flex-col w-full min-h-screen items-center App pt-12">
          <Home />

          <div className='flex grow' />

          <Footer />
        </div>

        <dialog id="customize_home_modal" className="modal modal-bottom sm:modal-middle">
          <CustomizeHomeModal />
        </dialog>

        <dialog id="log_txn_modal" className="modal modal-bottom sm:modal-middle">
          <TransactionInputModal />
        </dialog>

        <dialog id='register_income_modal' className='modal modal-bottom sm:modal-middle'>
          <RegisterIncomeModal />
        </dialog>

        <dialog id='local_prefs_modal' className='modal modal-bottom sm:modal-middle'>
          <LocalPrefsModal />
        </dialog>

      </SignedIn>

      <SignedOut>
        <SignedOutView />
      </SignedOut>
    </>
  );
}

export default App;

import Dexie, { Table } from "dexie";
import { Account, Income, LocalData, WidgetConfig } from "../gen/account/v1/account_pb";
import { Transaction, TransactionFrequency } from "../gen/transaction/v1/transaction_pb";
import { set } from "idb-keyval";

export interface ClientData {
	env: string;
	version: bigint;
	exchange_rates: { [key: string]: number };
}

export class PennyPalDB extends Dexie {
	// is it wise to allow multiple accounts?
	accounts!: Table<Account, string>;
	transactions!: Table<Transaction, string>;
	// bad name. more like local prefs.
	local_data!: Table<LocalData, string>;
	income_streams!: Table<Income, string>;

	// client only data. not sync'd with server.
	client_data!: Table<ClientData, string>;

	constructor() {
		super('penny_pal_db')
		this.version(1).stores({
			accounts: '++id',
			transactions: '++id,category,insertedAt,expiresAt',
			local_data: '++env',
			income_streams: '++id',
			client_data: '++env',
		})
	}

	commit(version?: bigint) {
		db.client_data.put({
			env: process.env.NODE_ENV,
			version: version || BigInt(Date.now()),
			exchange_rates: {},
		})
	}

	deleteIncomeStream(id: string): void {
		this.income_streams.where('id').equals(id).delete()
		this.commit()
	}

	deleteTransaction(id: string) {
		this.transactions.where('id').equals(id).delete()
		this.commit()
	}

	toggleCensored() {
		this.local_data.where('env').equals(process.env.NODE_ENV)
			.first().then((current) => {
				this.local_data.put({
					...current,
					censored: !current?.censored
				} as LocalData)
			})
	}

	allTransactionsForCategory(category: string) {
		return this.transactions
		.where('category')
		.equalsIgnoreCase(category)
		.toArray()
	}

	getTransactionSumByCategory(category: string) {
		const query = this.transactions
			.where('category')
			.equalsIgnoreCase(category)
			.toArray()

		return Promise.resolve(query)
			.then((txForCategory) => {
				let sum = 0;
				for (const tx of txForCategory) {
					sum += tx.amount?.value || 0.0
				}
				return sum;
			})
	}
}

export const db = new PennyPalDB()

const widget_def = (twine: string, pos: number, vis: boolean = true) => ({
	[twine]: {
		twine: twine,
		position: pos,
		visible: vis,
	}
})

const DEFAULT_WIDGET_ORDERING = {
	...widget_def('overview', 0),
	...widget_def('expenses_view', 1),
	...widget_def('income_streams', 2),
	...widget_def('table_view', 3, false)
}

const DEFAULT_CURRENCY_CODE = 'GBP'

// PROBLEM how can we handle with this being skewed from the
// schema on remote?

// we should have a local_data table configured on start
db.on('ready', (db) => {
	db.table('client_data').count((count) => {
		if (count == 0) {
			db.table('client_data').add({
				version: 0,
				env: process.env.NODE_ENV
			})
		}
	})

	db.table('local_data').count((count) => {
		if (count == 0) {
			db.table('local_data').add(new LocalData({
				env: process.env.NODE_ENV,
				version: BigInt(0),
				censored: false,
				primaryCurrencyCode: DEFAULT_CURRENCY_CODE,

				currentViewingPeriod: TransactionFrequency.MONTHLY,

				widgetConfig: {
					...DEFAULT_WIDGET_ORDERING
				}
			}))
		}
	})
})
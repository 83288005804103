// @generated by protoc-gen-es v1.9.0 with parameter "target=ts,import_extension=none"
// @generated from file util/v1/money.proto (package util.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum util.v1.UnitType
 */
export enum UnitType {
  /**
   * @generated from enum value: UNIT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: UNIT_TYPE_CURRENCY = 1;
   */
  CURRENCY = 1,

  /**
   * @generated from enum value: UNIT_TYPE_RSU = 2;
   */
  RSU = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UnitType)
proto3.util.setEnumType(UnitType, "util.v1.UnitType", [
  { no: 0, name: "UNIT_TYPE_UNSPECIFIED" },
  { no: 1, name: "UNIT_TYPE_CURRENCY" },
  { no: 2, name: "UNIT_TYPE_RSU" },
]);

/**
 * @generated from message util.v1.Money
 */
export class Money extends Message<Money> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: util.v1.UnitType unit_type = 3;
   */
  unitType = UnitType.UNSPECIFIED;

  constructor(data?: PartialMessage<Money>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "util.v1.Money";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unit_type", kind: "enum", T: proto3.getEnumType(UnitType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Money {
    return new Money().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJsonString(jsonString, options);
  }

  static equals(a: Money | PlainMessage<Money> | undefined, b: Money | PlainMessage<Money> | undefined): boolean {
    return proto3.util.equals(Money, a, b);
  }
}


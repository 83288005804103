// @generated by protoc-gen-es v1.9.0 with parameter "target=ts,import_extension=none"
// @generated from file ledger/v1/ledger.proto (package ledger.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Transaction } from "../../transaction/v1/transaction_pb";
import { Income, LocalData } from "../../account/v1/account_pb";
import { Error } from "../../util/v1/error_pb";

/**
 * @generated from message ledger.v1.GetAccountVersion
 */
export class GetAccountVersion extends Message<GetAccountVersion> {
  constructor(data?: PartialMessage<GetAccountVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAccountVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountVersion {
    return new GetAccountVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountVersion {
    return new GetAccountVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountVersion {
    return new GetAccountVersion().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountVersion | PlainMessage<GetAccountVersion> | undefined, b: GetAccountVersion | PlainMessage<GetAccountVersion> | undefined): boolean {
    return proto3.util.equals(GetAccountVersion, a, b);
  }
}

/**
 * @generated from message ledger.v1.GetAccountVersion.Request
 */
export class GetAccountVersion_Request extends Message<GetAccountVersion_Request> {
  constructor(data?: PartialMessage<GetAccountVersion_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAccountVersion.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountVersion_Request {
    return new GetAccountVersion_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountVersion_Request {
    return new GetAccountVersion_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountVersion_Request {
    return new GetAccountVersion_Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountVersion_Request | PlainMessage<GetAccountVersion_Request> | undefined, b: GetAccountVersion_Request | PlainMessage<GetAccountVersion_Request> | undefined): boolean {
    return proto3.util.equals(GetAccountVersion_Request, a, b);
  }
}

/**
 * @generated from message ledger.v1.GetAccountVersion.Response
 */
export class GetAccountVersion_Response extends Message<GetAccountVersion_Response> {
  /**
   * version is a unix timestamp
   *
   * @generated from field: int64 version = 1;
   */
  version = protoInt64.zero;

  constructor(data?: PartialMessage<GetAccountVersion_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAccountVersion.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountVersion_Response {
    return new GetAccountVersion_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountVersion_Response {
    return new GetAccountVersion_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountVersion_Response {
    return new GetAccountVersion_Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountVersion_Response | PlainMessage<GetAccountVersion_Response> | undefined, b: GetAccountVersion_Response | PlainMessage<GetAccountVersion_Response> | undefined): boolean {
    return proto3.util.equals(GetAccountVersion_Response, a, b);
  }
}

/**
 * @generated from message ledger.v1.PutAllTransactions
 */
export class PutAllTransactions extends Message<PutAllTransactions> {
  constructor(data?: PartialMessage<PutAllTransactions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.PutAllTransactions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PutAllTransactions {
    return new PutAllTransactions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PutAllTransactions {
    return new PutAllTransactions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PutAllTransactions {
    return new PutAllTransactions().fromJsonString(jsonString, options);
  }

  static equals(a: PutAllTransactions | PlainMessage<PutAllTransactions> | undefined, b: PutAllTransactions | PlainMessage<PutAllTransactions> | undefined): boolean {
    return proto3.util.equals(PutAllTransactions, a, b);
  }
}

/**
 * @generated from message ledger.v1.PutAllTransactions.Request
 */
export class PutAllTransactions_Request extends Message<PutAllTransactions_Request> {
  /**
   * @generated from field: repeated transaction.v1.Transaction tx = 1;
   */
  tx: Transaction[] = [];

  /**
   * @generated from field: repeated account.v1.Income is = 2;
   */
  is: Income[] = [];

  /**
   * @generated from field: account.v1.LocalData local_data = 3;
   */
  localData?: LocalData;

  constructor(data?: PartialMessage<PutAllTransactions_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.PutAllTransactions.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "is", kind: "message", T: Income, repeated: true },
    { no: 3, name: "local_data", kind: "message", T: LocalData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PutAllTransactions_Request {
    return new PutAllTransactions_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PutAllTransactions_Request {
    return new PutAllTransactions_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PutAllTransactions_Request {
    return new PutAllTransactions_Request().fromJsonString(jsonString, options);
  }

  static equals(a: PutAllTransactions_Request | PlainMessage<PutAllTransactions_Request> | undefined, b: PutAllTransactions_Request | PlainMessage<PutAllTransactions_Request> | undefined): boolean {
    return proto3.util.equals(PutAllTransactions_Request, a, b);
  }
}

/**
 * @generated from message ledger.v1.PutAllTransactions.Response
 */
export class PutAllTransactions_Response extends Message<PutAllTransactions_Response> {
  /**
   * @generated from field: util.v1.Error error = 1;
   */
  error?: Error;

  /**
   * @generated from field: int64 latest_version = 2;
   */
  latestVersion = protoInt64.zero;

  constructor(data?: PartialMessage<PutAllTransactions_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.PutAllTransactions.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error", kind: "message", T: Error },
    { no: 2, name: "latest_version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PutAllTransactions_Response {
    return new PutAllTransactions_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PutAllTransactions_Response {
    return new PutAllTransactions_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PutAllTransactions_Response {
    return new PutAllTransactions_Response().fromJsonString(jsonString, options);
  }

  static equals(a: PutAllTransactions_Response | PlainMessage<PutAllTransactions_Response> | undefined, b: PutAllTransactions_Response | PlainMessage<PutAllTransactions_Response> | undefined): boolean {
    return proto3.util.equals(PutAllTransactions_Response, a, b);
  }
}

/**
 * @generated from message ledger.v1.GetAllTransactions
 */
export class GetAllTransactions extends Message<GetAllTransactions> {
  constructor(data?: PartialMessage<GetAllTransactions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAllTransactions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllTransactions {
    return new GetAllTransactions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllTransactions {
    return new GetAllTransactions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllTransactions {
    return new GetAllTransactions().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllTransactions | PlainMessage<GetAllTransactions> | undefined, b: GetAllTransactions | PlainMessage<GetAllTransactions> | undefined): boolean {
    return proto3.util.equals(GetAllTransactions, a, b);
  }
}

/**
 * @generated from message ledger.v1.GetAllTransactions.Request
 */
export class GetAllTransactions_Request extends Message<GetAllTransactions_Request> {
  /**
   * filter by category
   *
   * @generated from field: optional string category = 1;
   */
  category?: string;

  constructor(data?: PartialMessage<GetAllTransactions_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAllTransactions.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllTransactions_Request {
    return new GetAllTransactions_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllTransactions_Request {
    return new GetAllTransactions_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllTransactions_Request {
    return new GetAllTransactions_Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllTransactions_Request | PlainMessage<GetAllTransactions_Request> | undefined, b: GetAllTransactions_Request | PlainMessage<GetAllTransactions_Request> | undefined): boolean {
    return proto3.util.equals(GetAllTransactions_Request, a, b);
  }
}

/**
 * @generated from message ledger.v1.GetAllTransactions.Response
 */
export class GetAllTransactions_Response extends Message<GetAllTransactions_Response> {
  /**
   * @generated from field: repeated transaction.v1.Transaction tx = 1;
   */
  tx: Transaction[] = [];

  /**
   * @generated from field: repeated account.v1.Income is = 2;
   */
  is: Income[] = [];

  /**
   * @generated from field: account.v1.LocalData local_data = 4;
   */
  localData?: LocalData;

  /**
   * @generated from field: int64 latest_version = 3;
   */
  latestVersion = protoInt64.zero;

  constructor(data?: PartialMessage<GetAllTransactions_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.GetAllTransactions.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "is", kind: "message", T: Income, repeated: true },
    { no: 4, name: "local_data", kind: "message", T: LocalData },
    { no: 3, name: "latest_version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllTransactions_Response {
    return new GetAllTransactions_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllTransactions_Response {
    return new GetAllTransactions_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllTransactions_Response {
    return new GetAllTransactions_Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllTransactions_Response | PlainMessage<GetAllTransactions_Response> | undefined, b: GetAllTransactions_Response | PlainMessage<GetAllTransactions_Response> | undefined): boolean {
    return proto3.util.equals(GetAllTransactions_Response, a, b);
  }
}

/**
 * @generated from message ledger.v1.ConvertCurrency
 */
export class ConvertCurrency extends Message<ConvertCurrency> {
  constructor(data?: PartialMessage<ConvertCurrency>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.ConvertCurrency";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCurrency {
    return new ConvertCurrency().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCurrency {
    return new ConvertCurrency().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCurrency {
    return new ConvertCurrency().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCurrency | PlainMessage<ConvertCurrency> | undefined, b: ConvertCurrency | PlainMessage<ConvertCurrency> | undefined): boolean {
    return proto3.util.equals(ConvertCurrency, a, b);
  }
}

/**
 * @generated from message ledger.v1.ConvertCurrency.Request
 */
export class ConvertCurrency_Request extends Message<ConvertCurrency_Request> {
  /**
   * @generated from field: string base_currency_code = 1;
   */
  baseCurrencyCode = "";

  /**
   * @generated from field: repeated string target_currency_code = 2;
   */
  targetCurrencyCode: string[] = [];

  constructor(data?: PartialMessage<ConvertCurrency_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.ConvertCurrency.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCurrency_Request {
    return new ConvertCurrency_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCurrency_Request {
    return new ConvertCurrency_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCurrency_Request {
    return new ConvertCurrency_Request().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCurrency_Request | PlainMessage<ConvertCurrency_Request> | undefined, b: ConvertCurrency_Request | PlainMessage<ConvertCurrency_Request> | undefined): boolean {
    return proto3.util.equals(ConvertCurrency_Request, a, b);
  }
}

/**
 * @generated from message ledger.v1.ConvertCurrency.Response
 */
export class ConvertCurrency_Response extends Message<ConvertCurrency_Response> {
  /**
   * @generated from field: map<string, double> rates = 1;
   */
  rates: { [key: string]: number } = {};

  constructor(data?: PartialMessage<ConvertCurrency_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ledger.v1.ConvertCurrency.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rates", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCurrency_Response {
    return new ConvertCurrency_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCurrency_Response {
    return new ConvertCurrency_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCurrency_Response {
    return new ConvertCurrency_Response().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCurrency_Response | PlainMessage<ConvertCurrency_Response> | undefined, b: ConvertCurrency_Response | PlainMessage<ConvertCurrency_Response> | undefined): boolean {
    return proto3.util.equals(ConvertCurrency_Response, a, b);
  }
}


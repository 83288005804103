// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file ledger/v1/ledger.proto (package ledger.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConvertCurrency_Request, ConvertCurrency_Response, GetAccountVersion_Request, GetAccountVersion_Response, GetAllTransactions_Request, GetAllTransactions_Response, PutAllTransactions_Request, PutAllTransactions_Response } from "./ledger_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ledger.v1.LedgerService
 */
export const LedgerService = {
  typeName: "ledger.v1.LedgerService",
  methods: {
    /**
     * @generated from rpc ledger.v1.LedgerService.GetAccountVersion
     */
    getAccountVersion: {
      name: "GetAccountVersion",
      I: GetAccountVersion_Request,
      O: GetAccountVersion_Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ledger.v1.LedgerService.PutAllTransactions
     */
    putAllTransactions: {
      name: "PutAllTransactions",
      I: PutAllTransactions_Request,
      O: PutAllTransactions_Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ledger.v1.LedgerService.GetAllTransactions
     */
    getAllTransactions: {
      name: "GetAllTransactions",
      I: GetAllTransactions_Request,
      O: GetAllTransactions_Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ledger.v1.LedgerService.ConvertCurrency
     */
    convertCurrency: {
      name: "ConvertCurrency",
      I: ConvertCurrency_Request,
      O: ConvertCurrency_Response,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { Timestamp } from "@bufbuild/protobuf";
import { Transaction, TransactionFrequency } from "../gen/transaction/v1/transaction_pb";
import { Income } from "../gen/account/v1/account_pb";
import moment from "moment";

const transactionWithinRange = (tx: Transaction) => {
  // no insertion date something must be up
  if (!tx.insertedAt) {
    return false
  }
  
  // no expiry date, we're always considering this
  // transaction in the accounts.
  if (!tx.expiresAt) {
    return true
  }

  const expiryDate = moment(tx.insertedAt.toDate())
  expiryDate.add(tx.expiresAt.seconds.toString(), 'seconds')

  return expiryDate.isAfter(moment())
}

class LocalLedgerBackend {
  static convert(exchange_rates: { [key: string]: number }, amount: number, from: string, to: string): number {
    if (from === to) {
      return amount
    }
    return amount / exchange_rates[from]
  }
  
  /**
   * calculate how much money we have left over as of 
   * the given timestamp.
   * @param transactions 
   * @param time 
   */
  static calculateExcess(baseCurrencyCode: string, exchange_rates: { [key: string]: number }, incomeStreams: Income[], transactions: Transaction[], time: Timestamp, period: TransactionFrequency = TransactionFrequency.MONTHLY) {
    const incomePerX =
      incomeStreams
        .map((is) => ({
          amount: LocalLedgerBackend.convert(exchange_rates, (is.amount?.value || 0), is.amount?.code || baseCurrencyCode, baseCurrencyCode),
          cadence: is.payoutCadence,
        }))
        .map(({ amount, cadence }) => LocalLedgerBackend.normalize(amount, cadence, period))
        .reduce((prev, curr) => prev + curr, 0)

    const outgoingsPerX =
      transactions
        .filter((tx: Transaction) => transactionWithinRange(tx))
        .map((is: Transaction) => ({
          amount: LocalLedgerBackend.convert(exchange_rates, (is.amount?.value || 0), is.amount?.code || baseCurrencyCode, baseCurrencyCode),
          cadence: is.frequency,
        }))
        .map(({ amount, cadence }) => LocalLedgerBackend.normalize(amount, cadence, period))
        .reduce((prev, curr) => prev + curr, 0)

    console.log(transactions, incomeStreams)
    console.log(incomePerX, outgoingsPerX)

    return incomePerX - outgoingsPerX
  }

  static conversionFactors: { [key in TransactionFrequency]?: { [key in TransactionFrequency]?: number } } = {
    [TransactionFrequency.DAILY]: {
      [TransactionFrequency.WEEKLY]: 1 / 7,
      [TransactionFrequency.BI_WEEKLY]: 1 / 14,
      [TransactionFrequency.MONTHLY]: 1 / 30,
      [TransactionFrequency.QUARTERLY]: 1 / 91.25,
      [TransactionFrequency.HALF_YEARLY]: 1 / 182.5,
      [TransactionFrequency.YEARLY]: 1 / 365,
    },
    [TransactionFrequency.WEEKLY]: {
      [TransactionFrequency.DAILY]: 7,
      [TransactionFrequency.BI_WEEKLY]: 1 / 2,
      [TransactionFrequency.MONTHLY]: 1 / 4,
      [TransactionFrequency.QUARTERLY]: 1 / 13,
      [TransactionFrequency.HALF_YEARLY]: 1 / 26,
      [TransactionFrequency.YEARLY]: 1 / 52,
    },
    [TransactionFrequency.BI_WEEKLY]: {
      [TransactionFrequency.DAILY]: 14,
      [TransactionFrequency.WEEKLY]: 2,
      [TransactionFrequency.MONTHLY]: 1 / 2,
      [TransactionFrequency.QUARTERLY]: 1 / 6.5,
      [TransactionFrequency.HALF_YEARLY]: 1 / 13,
      [TransactionFrequency.YEARLY]: 1 / 26,
    },
    [TransactionFrequency.MONTHLY]: {
      [TransactionFrequency.DAILY]: 1 / 30,
      [TransactionFrequency.WEEKLY]: 1 / 4,
      [TransactionFrequency.BI_WEEKLY]: 1 / 2,
      [TransactionFrequency.QUARTERLY]: 3,
      [TransactionFrequency.HALF_YEARLY]: 6,
      [TransactionFrequency.YEARLY]: 12,
    },
    [TransactionFrequency.QUARTERLY]: {
      [TransactionFrequency.DAILY]: 1 / 91.25,
      [TransactionFrequency.WEEKLY]: 1 / 13,
      [TransactionFrequency.BI_WEEKLY]: 1 / 6.5,
      [TransactionFrequency.MONTHLY]: 1 / 3,
      [TransactionFrequency.HALF_YEARLY]: 2,
      [TransactionFrequency.YEARLY]: 4,
    },
    [TransactionFrequency.HALF_YEARLY]: {
      [TransactionFrequency.DAILY]: 1 / 182.5,
      [TransactionFrequency.WEEKLY]: 1 / 26,
      [TransactionFrequency.BI_WEEKLY]: 1 / 13,
      [TransactionFrequency.MONTHLY]: 1 / 6,
      [TransactionFrequency.QUARTERLY]: 1 / 2,
      [TransactionFrequency.YEARLY]: 2,
    },
    [TransactionFrequency.YEARLY]: {
      [TransactionFrequency.DAILY]: 1 / 365,
      [TransactionFrequency.WEEKLY]: 1 / 52,
      [TransactionFrequency.BI_WEEKLY]: 1 / 26,
      [TransactionFrequency.MONTHLY]: 1 / 12,
      [TransactionFrequency.QUARTERLY]: 1 / 4,
      [TransactionFrequency.HALF_YEARLY]: 1 / 2,
    },
  };

  static normalize(value: number, from: TransactionFrequency, convertTo: TransactionFrequency): number {
    if (convertTo == from) {
      return value || 0
    }
    
    const factor = LocalLedgerBackend.conversionFactors[from]?.[convertTo];
    if (factor === undefined) {
      return value // NOOP for now
      // throw new Error(`Conversion from ${TransactionFrequency[from]} to ${TransactionFrequency[convertTo]} is not supported.`);
    }

    console.log('yo', value, factor)

    // value can be NaN for some reason
    return (value || 0) * factor;
  }
}

export {
  LocalLedgerBackend
}
// NOTE:

// transaction could have a hash (ignoring the ID)
// so we can identify duplicates?

// the idea is that the client will send all transactions over in batches
// saving on a N-second/minute interval.
// - how do we handle deletes? if a delete registers on the db
//   we need to somehow delete on the kafka-side. this means a tombstone message
//   IDEA: any operation happens on the DB goes to a log within the database. this is what gets synced to the backend.

// @generated by protoc-gen-es v1.9.0 with parameter "target=ts,import_extension=none"
// @generated from file transaction/v1/transaction.proto (package transaction.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum transaction.v1.UnitType
 */
export enum UnitType {
  /**
   * @generated from enum value: UNIT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: UNIT_TYPE_CURRENCY = 1;
   */
  CURRENCY = 1,

  /**
   * @generated from enum value: UNIT_TYPE_RSU = 2;
   */
  RSU = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UnitType)
proto3.util.setEnumType(UnitType, "transaction.v1.UnitType", [
  { no: 0, name: "UNIT_TYPE_UNSPECIFIED" },
  { no: 1, name: "UNIT_TYPE_CURRENCY" },
  { no: 2, name: "UNIT_TYPE_RSU" },
]);

/**
 * @generated from enum transaction.v1.TransactionFrequency
 */
export enum TransactionFrequency {
  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_ONE_OFF = 1;
   */
  ONE_OFF = 1,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_DAILY = 2;
   */
  DAILY = 2,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_WEEKLY = 3;
   */
  WEEKLY = 3,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_BI_WEEKLY = 4;
   */
  BI_WEEKLY = 4,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_MONTHLY = 5;
   */
  MONTHLY = 5,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_QUARTERLY = 6;
   */
  QUARTERLY = 6,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_HALF_YEARLY = 7;
   */
  HALF_YEARLY = 7,

  /**
   * @generated from enum value: TRANSACTION_FREQUENCY_YEARLY = 8;
   */
  YEARLY = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(TransactionFrequency)
proto3.util.setEnumType(TransactionFrequency, "transaction.v1.TransactionFrequency", [
  { no: 0, name: "TRANSACTION_FREQUENCY_UNSPECIFIED" },
  { no: 1, name: "TRANSACTION_FREQUENCY_ONE_OFF" },
  { no: 2, name: "TRANSACTION_FREQUENCY_DAILY" },
  { no: 3, name: "TRANSACTION_FREQUENCY_WEEKLY" },
  { no: 4, name: "TRANSACTION_FREQUENCY_BI_WEEKLY" },
  { no: 5, name: "TRANSACTION_FREQUENCY_MONTHLY" },
  { no: 6, name: "TRANSACTION_FREQUENCY_QUARTERLY" },
  { no: 7, name: "TRANSACTION_FREQUENCY_HALF_YEARLY" },
  { no: 8, name: "TRANSACTION_FREQUENCY_YEARLY" },
]);

/**
 * @generated from message transaction.v1.Amount
 */
export class Amount extends Message<Amount> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: transaction.v1.UnitType unit_type = 3;
   */
  unitType = UnitType.UNSPECIFIED;

  constructor(data?: PartialMessage<Amount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "transaction.v1.Amount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unit_type", kind: "enum", T: proto3.getEnumType(UnitType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Amount {
    return new Amount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Amount {
    return new Amount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Amount {
    return new Amount().fromJsonString(jsonString, options);
  }

  static equals(a: Amount | PlainMessage<Amount> | undefined, b: Amount | PlainMessage<Amount> | undefined): boolean {
    return proto3.util.equals(Amount, a, b);
  }
}

/**
 * @generated from message transaction.v1.Transaction
 */
export class Transaction extends Message<Transaction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string category = 3;
   */
  category = "";

  /**
   * @generated from field: transaction.v1.Amount amount = 4;
   */
  amount?: Amount;

  /**
   * @generated from field: transaction.v1.TransactionFrequency frequency = 5;
   */
  frequency = TransactionFrequency.UNSPECIFIED;

  /**
   * @generated from field: optional string additional_note = 6;
   */
  additionalNote?: string;

  /**
   * @generated from field: google.protobuf.Timestamp inserted_at = 7;
   */
  insertedAt?: Timestamp;

  /**
   * deprecate me.
   *
   * @generated from field: optional google.protobuf.Timestamp recurs_at = 8;
   */
  recursAt?: Timestamp;

  /**
   * @generated from field: string account_id = 9;
   */
  accountId = "";

  /**
   * expires_at defines when a transaction is no longer
   * in the books.
   *
   * @generated from field: optional google.protobuf.Duration expires_at = 10;
   */
  expiresAt?: Duration;

  constructor(data?: PartialMessage<Transaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "transaction.v1.Transaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "message", T: Amount },
    { no: 5, name: "frequency", kind: "enum", T: proto3.getEnumType(TransactionFrequency) },
    { no: 6, name: "additional_note", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "inserted_at", kind: "message", T: Timestamp },
    { no: 8, name: "recurs_at", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "expires_at", kind: "message", T: Duration, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Transaction {
    return new Transaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJsonString(jsonString, options);
  }

  static equals(a: Transaction | PlainMessage<Transaction> | undefined, b: Transaction | PlainMessage<Transaction> | undefined): boolean {
    return proto3.util.equals(Transaction, a, b);
  }
}


// @generated by protoc-gen-es v1.9.0 with parameter "target=ts,import_extension=none"
// @generated from file account/v1/account.proto (package account.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { TransactionFrequency } from "../../transaction/v1/transaction_pb";
import { Money } from "../../util/v1/money_pb";

/**
 * @generated from message account.v1.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string primary_currency_code = 3;
   */
  primaryCurrencyCode = "";

  /**
   * @generated from field: google.protobuf.Timestamp inserted_at = 4;
   */
  insertedAt?: Timestamp;

  /**
   * @generated from field: bool censored = 5;
   */
  censored = false;

  /**
   * @generated from field: int64 version = 6;
   */
  version = protoInt64.zero;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "primary_currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "inserted_at", kind: "message", T: Timestamp },
    { no: 5, name: "censored", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message account.v1.Profile
 */
export class Profile extends Message<Profile> {
  /**
   * equates to user_id from clerk.
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string alias = 2;
   */
  alias = "";

  constructor(data?: PartialMessage<Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.Profile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profile {
    return new Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJsonString(jsonString, options);
  }

  static equals(a: Profile | PlainMessage<Profile> | undefined, b: Profile | PlainMessage<Profile> | undefined): boolean {
    return proto3.util.equals(Profile, a, b);
  }
}

/**
 * @generated from message account.v1.WidgetConfig
 */
export class WidgetConfig extends Message<WidgetConfig> {
  /**
   * @generated from field: string twine = 1;
   */
  twine = "";

  /**
   * @generated from field: int32 position = 2;
   */
  position = 0;

  /**
   * @generated from field: bool visible = 3;
   */
  visible = false;

  constructor(data?: PartialMessage<WidgetConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.WidgetConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "twine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WidgetConfig {
    return new WidgetConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WidgetConfig {
    return new WidgetConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WidgetConfig {
    return new WidgetConfig().fromJsonString(jsonString, options);
  }

  static equals(a: WidgetConfig | PlainMessage<WidgetConfig> | undefined, b: WidgetConfig | PlainMessage<WidgetConfig> | undefined): boolean {
    return proto3.util.equals(WidgetConfig, a, b);
  }
}

/**
 * @generated from message account.v1.LocalData
 */
export class LocalData extends Message<LocalData> {
  /**
   * @generated from field: string env = 1;
   */
  env = "";

  /**
   * @generated from field: int64 version = 2;
   */
  version = protoInt64.zero;

  /**
   * @generated from field: bool censored = 3;
   */
  censored = false;

  /**
   * @generated from field: string primary_currency_code = 4;
   */
  primaryCurrencyCode = "";

  /**
   * @generated from field: transaction.v1.TransactionFrequency current_viewing_period = 5;
   */
  currentViewingPeriod = TransactionFrequency.UNSPECIFIED;

  /**
   * @generated from field: map<string, account.v1.WidgetConfig> widget_config = 6;
   */
  widgetConfig: { [key: string]: WidgetConfig } = {};

  constructor(data?: PartialMessage<LocalData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.LocalData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "env", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "censored", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "primary_currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "current_viewing_period", kind: "enum", T: proto3.getEnumType(TransactionFrequency) },
    { no: 6, name: "widget_config", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: WidgetConfig} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocalData {
    return new LocalData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocalData {
    return new LocalData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocalData {
    return new LocalData().fromJsonString(jsonString, options);
  }

  static equals(a: LocalData | PlainMessage<LocalData> | undefined, b: LocalData | PlainMessage<LocalData> | undefined): boolean {
    return proto3.util.equals(LocalData, a, b);
  }
}

/**
 * @generated from message account.v1.TransactionSplit
 */
export class TransactionSplit extends Message<TransactionSplit> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: repeated string transaction_id = 3;
   */
  transactionId: string[] = [];

  /**
   * e.g. 0.33 of the transaction split.
   *
   * @generated from field: double split_amount = 4;
   */
  splitAmount = 0;

  constructor(data?: PartialMessage<TransactionSplit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.TransactionSplit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "split_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionSplit {
    return new TransactionSplit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionSplit {
    return new TransactionSplit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionSplit {
    return new TransactionSplit().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionSplit | PlainMessage<TransactionSplit> | undefined, b: TransactionSplit | PlainMessage<TransactionSplit> | undefined): boolean {
    return proto3.util.equals(TransactionSplit, a, b);
  }
}

/**
 * Income, i.e. a savings account (0 income)
 * or a salary
 * For now this is registered as a post-tax incomes?
 *
 * @generated from message account.v1.Income
 */
export class Income extends Message<Income> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string primary_currency_code = 3;
   */
  primaryCurrencyCode = "";

  /**
   * @generated from field: google.protobuf.Timestamp inserted_at = 4;
   */
  insertedAt?: Timestamp;

  /**
   * @generated from field: transaction.v1.TransactionFrequency payout_cadence = 5;
   */
  payoutCadence = TransactionFrequency.UNSPECIFIED;

  /**
   * todo(felix): deprecate this fully
   *
   * @generated from field: optional util.v1.Money amount = 6;
   */
  amount?: Money;

  /**
   * amount of income as per the given epoch.
   *
   * @generated from field: map<int64, util.v1.Money> amount_from_epoch = 7;
   */
  amountFromEpoch: { [key: string]: Money } = {};

  constructor(data?: PartialMessage<Income>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "account.v1.Income";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "primary_currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "inserted_at", kind: "message", T: Timestamp },
    { no: 5, name: "payout_cadence", kind: "enum", T: proto3.getEnumType(TransactionFrequency) },
    { no: 6, name: "amount", kind: "message", T: Money, opt: true },
    { no: 7, name: "amount_from_epoch", kind: "map", K: 3 /* ScalarType.INT64 */, V: {kind: "message", T: Money} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Income {
    return new Income().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Income {
    return new Income().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Income {
    return new Income().fromJsonString(jsonString, options);
  }

  static equals(a: Income | PlainMessage<Income> | undefined, b: Income | PlainMessage<Income> | undefined): boolean {
    return proto3.util.equals(Income, a, b);
  }
}

